import React, { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import bg from "../assets/images/bg.png";
import vs from "../assets/images/vs.png";
import n1 from "../assets/images/n1.png";
import n2 from "../assets/images/n2.png";
import q1 from "../assets/images/q1.png";
import q2 from "../assets/images/q2.png";
import q3 from "../assets/images/q3.png";
import q4 from "../assets/images/q3.png";
import gameOver from "../assets/animations/GameOver.mp4";

function VideoPlayer({ matchData, videos }) {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [status, setStatus] = useState("start");

  useEffect(() => {
    let timer;
    if (status === "start") {
      // timer = setTimeout(() => setStatus("round"), 3000);
      timer = setTimeout(() => setStatus("video"), 3000);
    } 
    // else if (status === "round") {
    //   timer = setTimeout(() => setStatus("video"), 2000);
    // } 
    else if (status === "video") {
      timer = setTimeout(() => {
        if (currentVideoIndex < videos.length - 1) {
          setCurrentVideoIndex(currentVideoIndex + 1);
          // setStatus("round");
        } 
        // else {
          // setStatus("end");
        // }
        setStatus("video");
      }, videos[currentVideoIndex]?.duration * 1000);
    }
    return () => clearTimeout(timer);
  }, [status, currentVideoIndex]);

  return (
    <div className="video-container">
      <TransitionGroup>
        {status === "start" && (
          <CSSTransition key="start" timeout={500} classNames="fade">
            <div
              className="start-animation animation_wrapper"
              style={{ backgroundImage: `url(${bg})` }}
            >
              <div className="game_animation_round_wrapper">
                <div className="left_wrapper">
                  <div
                    className="team_logo"
                    style={{
                      backgroundImage: `url(${matchData?.image_url_home})`,
                    }}
                  />
                  <div
                    className="team_name"
                    style={{ backgroundImage: `url(${n1})` }}
                  >
                    <p>{matchData.TeamNamehome.toUpperCase()}</p>
                  </div>
                </div>
                <div className="center_wrapper">
                  <div
                    className="vs_logo"
                    style={{ backgroundImage: `url(${vs})` }}
                  />
                </div>
                <div className="right_wrapper">
                  <div
                    className="team_logo"
                    style={{
                      backgroundImage: `url(${matchData?.image_url_away})`,
                    }}
                  />
                  <div
                    className="team_name"
                    style={{ backgroundImage: `url(${n2})` }}
                  >
                    <p>{matchData.TeamNameaway.toUpperCase()}</p>
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
        )}
        {/* {status === "round" && (
          <CSSTransition
            key={`round-${currentVideoIndex}`}
            timeout={500}
            classNames="fade"
          >
            <div
              className="start-animation animation_wrapper"
              style={{ backgroundImage: `url(${bg})` }}
            >
              <div className="game_animation_round_wrapper">
                <div className="left_wrapper">
                  <div
                    className="team_logo"
                    style={{
                      backgroundImage: `url(${matchData?.image_url_home})`,
                    }}
                  />
                  <div
                    className="team_name"
                    style={{ backgroundImage: `url(${n1})` }}
                  >
                    <p>
                      {currentVideoIndex === 0
                        ? matchData.Q1HomeScore
                        : currentVideoIndex === 1
                        ? matchData.Q2HomeScore
                        : currentVideoIndex === 2
                        ? matchData.Q3HomeScore
                        : currentVideoIndex === 3
                        ? matchData.Q4HomeScore
                        : null}
                    </p>
                  </div>
                </div>
                <div className="center_wrapper">
                  <div
                    className="vs_logo"
                    style={{
                      backgroundImage: `url(${
                        currentVideoIndex === 0
                          ? q1
                          : currentVideoIndex === 1
                          ? q2
                          : currentVideoIndex === 2
                          ? q3
                          : currentVideoIndex === 3
                          ? q4
                          : null
                      })`,
                    }}
                  />
                </div>
                <div className="right_wrapper">
                  <div
                    className="team_logo"
                    style={{
                      backgroundImage: `url(${matchData?.image_url_away})`,
                    }}
                  />
                  <div
                    className="team_name"
                    style={{ backgroundImage: `url(${n2})` }}
                  >
                    <p>
                      {currentVideoIndex === 0
                        ? matchData.Q1AwayScore
                        : currentVideoIndex === 1
                        ? matchData.Q2AwayScore
                        : currentVideoIndex === 2
                        ? matchData.Q3AwayScore
                        : currentVideoIndex === 3
                        ? matchData.Q4AwayScore
                        : null}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
        )} */}
        {status === "video" && (
          <CSSTransition
            key={`video-${currentVideoIndex}`}
            timeout={500}
            classNames="fade"
          >
            <video
              key={videos[currentVideoIndex]?.video_fullpath}
              src={videos[currentVideoIndex]?.video_fullpath}
              autoPlay
              // controls
              // onEnded={() => {
              //   if (currentVideoIndex < videos.length - 1) {
              //     setStatus("round");
              //   } else {
              //     setStatus("end");
              //   }
              // }}
            />
          </CSSTransition>
        )}
        {/* {status === "end" && (
          <CSSTransition key="end" timeout={500} classNames="fade">
            <div className="end-animation">
              <video
                src={gameOver}
                type="video/mp4"
                width="100%"
                height="auto"
                loop="loop"
                muted="muted"
                autoplay="true"
              />
            </div>
          </CSSTransition>
        )} */}
      </TransitionGroup>
    </div>
  );
}

export default VideoPlayer;

import React, { memo, useEffect, useState } from "react";
import { Audio } from "react-loader-spinner";
import { objectToFormData } from "../functions";
import { CRUDServices } from "../services/CRUDServices";
import language from "../language.json";
import { useParams } from "react-router-dom";

const Schedule = () => {
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(false);
  const { league = "nba" } = useParams();

  const months = [
    language?.schedule?.Jan,
    language?.schedule?.Feb,
    language?.schedule?.Mar,
    language?.schedule?.Apr,
    language?.schedule?.May,
    language?.schedule?.Jun,
    language?.schedule?.Jul,
    language?.schedule?.Aug,
    language?.schedule?.Sep,
    language?.schedule?.Oct,
    language?.schedule?.Nov,
    language?.schedule?.Dec,
  ];
  const days = [
    language?.schedule?.Sun,
    language?.schedule?.Mon,
    language?.schedule?.Tue,
    language?.schedule?.Wed,
    language?.schedule?.Thurs,
    language?.schedule?.Fri,
    language?.schedule?.Sat,
  ];

  const getFantasyScheduleByleague = async () => {
    setLoading(true);
    var payload = objectToFormData({
      exchange_slug: league,
      api_username: process.env.REACT_APP_USERNAME,
      api_password: process.env.REACT_APP_PASSWORD,
    });
    const response = await CRUDServices.POST(
      "get_fantasy_schedule_by_exchange",
      payload
    );
    if (response.status === 200) {
      setSchedules(response?.data?.data);
      setLoading(false);
    } else {
      setSchedules([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getFantasyScheduleByleague();
  }, [league]);

  return (
    <div className="col-lg-12 mt-3">
      <div className="sport_card">
        <div className="sport-heading">
          <p className="mb-0">{language?.schedule?.SCHEDULE}</p>
        </div>
        <div className="tableresponsive">
          <div className="table-scroll-left schedule-section">
            {!loading ? (
              <>
                {schedules.length !== 0 ? (
                  schedules?.map((item,index) => {
                    let date = new Date(String(item.scheduledate));
                    let day = date.getDate();
                    day = day < 10 ? "0" + day : day;
                    let monthNum = date.getMonth();
                    let month = months[monthNum];
                    let weekDayNum = date.getDay();
                    let weekDay = days[weekDayNum];

                    let hours = date.getHours();
                    let minutes = date.getMinutes();
                    let ampm = hours >= 12 ? "pm" : "am";
                    hours = hours % 12;
                    hours = hours ? hours : 12; // the hour '0' should be '12'
                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    let strTime = hours + ":" + minutes + " " + ampm;

                    return (
                      
                        <div className="text-white schedule_table_row" key={index}>
                          <div className="">
                            <div className="">
                              <span>{`${month}, ${day} `}</span> <br />
                              <span>{`${strTime}`}</span>
                            </div>
                            <div className="">
                              <div className="">
                                <div className="">
                                  <img src={item.image_url_home} alt="team" />{" "}
                                </div>
                                <div>{item.hometeamname}</div>
                              </div>
                              <p className="">{language?.schedule?.vs}</p>
                              <div className="">
                                <div className="">
                                  <img src={item.image_url_away} alt="team" />{" "}
                                </div>
                                <div>{item.awayteamname}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                    );
                  })
                ) : (
                  <div className="flex-standings">
                    <span className="center color-white">
                      {language?.all_leagues?.noDataFound}
                    </span>
                  </div>
                )}
              </>
            ) : (
              <div className="flex-standings">
                <Audio
                  height="180"
                  width="120"
                  radius="200"
                  color="white"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Schedule);

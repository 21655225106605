import React, { memo, useEffect, useState } from "react";
import { Audio } from "react-loader-spinner";
import { CRUDServices } from "../services/CRUDServices";
import { objectToFormData } from "../functions";
import { useParams } from "react-router-dom";
import language from "../language.json";

const Standings = () => {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(false);
  const { league = "nba" } = useParams();

  const getFantasyStandingByExchange = async () => {
    setLoading(true);
    var payload = objectToFormData({
      exchange_slug: league,
      api_username: process.env.REACT_APP_USERNAME,
      api_password: process.env.REACT_APP_PASSWORD,
    });
    const response = await CRUDServices.POST(
      "get_fantasy_standing_by_exchange",
      payload
    );
    if (response.status === 200) {
      setStandings(response?.data?.data);
      setLoading(false);
    } else {
      setStandings([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getFantasyStandingByExchange();
  }, [league]);

  return (
    <div className="col-lg-12 mt-3 standing-section">
      <div className="sport_card">
        <div className="sport-heading">
          <p className="mb-0">{language?.standing?.STANDINGS}</p>
        </div>
        <div className="tableresponsive">
          {!loading ? (
            <>
              {standings.length !== 0 ? (
                <div>
                  {/* <div className="d-flex justify-content-between align-items-center table-head bg-table standingsec">
                    <p className="pl-1 mb-0">{language?.standing?.Team}</p>
                    <div className="standing-hpts">
                      <p className="mb-0">{language?.standing?.W}</p>
                      <p className="mb-0">{language?.standing?.L}</p>
                      <p className="mb-0 mr-10">{language?.standing?.Pct}</p>
                    </div>
                  </div>
                  <div className="table-scroll-left standings-section">
                    {standings?.map((item) => {
                      var stats = item.Win_Loss.split(" ");
                      return (
                        <div className="d-flex justify-content-between align-items-center table-head">
                          <p className="mb-0 teamLogo">
                            <img src={item.image_url} />
                            <span className="ml-10">{item.TeamName}</span>
                          </p>
                          <div className="standing-pts">
                            <p className="mb-0">{stats[0]}</p>
                            <p className="mb-0">{stats[1]}</p>
                            <p className="mb-0">{item.pct}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div> */}
                  {/* --------------------------------------------------------------------------------------- */}
                  <div className="text-white standing_table_header">
                    <p className="">{language?.standing?.Team}</p>
                    <p className="">{language?.standing?.W}</p>
                    <p className="">{language?.standing?.L}</p>
                    <p className="">{language?.standing?.Pct}</p>
                  </div>
                  <div className="text-white standing_table_body">
                    {standings?.map((item,index) => {
                      var stats = item.Win_Loss.split(" ");
                      return (
                        <div className="" key={index}>
                          <div className="">
                            <img src={item.image_url} />
                            <span className="">{item.TeamName}</span>
                          </div>
                          <div className="">
                            <p className="">{stats[0]}</p>
                            <p className="">{stats[1]}</p>
                            <p className="">{item.pct}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/* --------------------------------------------------------------------------------------- */}
                </div>
              ) : (
                <div className="flex-standings">
                  <span className="center color-white">
                    {language?.all_leagues?.noDataFound}
                  </span>
                </div>
              )}
            </>
          ) : (
            <div className="flex-standings">
              <Audio
                height="180"
                width="120"
                radius="200"
                color="white"
                ariaLabel="loading"
                wrapperStyle
                wrapperClass
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Standings);

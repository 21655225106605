import React, { useState } from "react";
import PlayerStatus from "./PlayerStatus";

const TeamTable = ({ activeTeam, selectedTeam, matchData, changeTeam }) => {
  const [activePlayer, setActivePlayer] = useState({});
  const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);

  return (
    <div className="text-white">
      <PlayerStatus
        isPlayerModalOpen={isPlayerModalOpen}
        setIsPlayerModalOpen={setIsPlayerModalOpen}
        activePlayer={activePlayer}
      />
      <div className="d-flex justify-content-center align-items-center">
        <div className="d-flex justify-content-center align-items-center border border-primary cursor_p">
          <p
            className={`m-0 p-2 ${
              activeTeam === "home" ? "text-white" : "text-primary"
            } ${activeTeam === "home" ? "bg-primary" : ""}`}
            onClick={() => changeTeam("home")}
          >
            {matchData?.TeamNamehome}
          </p>
          <p
            className={`m-0 p-2 ${
              activeTeam === "away" ? "text-white" : "text-primary"
            } ${activeTeam === "away" ? "bg-primary" : ""}`}
            onClick={() => changeTeam("away")}
          >
            {matchData?.TeamNameaway}
          </p>
        </div>
      </div>
      <div className="team_table_wrapper">
        <div className="team_data_wrapper p-md-4 px-sm-2 py-sm-4">
          <div className="d-flex justify-content-start align-items-center">
            <p>Name</p>
            <p>BLK</p>
            <p>DRB</p>
            <p>GS</p>
            <p>MP</p>
            <p>PTS</p>
            <p>STL</p>
            <p>TOV</p>
            <p>Ptsmin</p>
            <p>Season</p>
          </div>
          {selectedTeam.map((st) => (
            <div
              key={st?.Playersname}
              className="d-flex justify-content-start align-items-center"
            >
              <p
                className="cursor_p"
                onClick={() => {
                  setActivePlayer(st);
                  setIsPlayerModalOpen(true);
                }}
              >
                {st?.Playersname}
              </p>
              <p>{st?.BLK}</p>
              <p>{st?.DRB}</p>
              <p>{st?.GS}</p>
              <p>{st?.MP}</p>
              <p>{st?.PTS}</p>
              <p>{st?.STL}</p>
              <p>{st?.TOV}</p>
              <p>{st?.ptsmin}</p>
              <p>{st?.season}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamTable;

import React, { useEffect, useState } from "react";
import language from '../language.json'

const Bet = ({ item, handleDeleteBet, bets, setBets, index }) => {
  const [wagerAmount, setWagerAmount] = useState("");
  const [toWinAmount, setToWinAmount] = useState(0);
  const [betTime, setBetTime] = useState("");

  const handleBetAmountChange = (e) => {
    const amount = e.target.value;
    setWagerAmount(amount);
    var odds = item?.data?.odds;
    var winningAmount = Number(e.target.value);
    setToWinAmount(winningAmount);

    var newBets = [...bets];
    newBets[index].data.wagerAmount = amount;
    newBets[index].data.winningAmount = winningAmount;
    setBets(newBets);
    localStorage.setItem("bets", JSON.stringify(bets));
  };

  useEffect(() => {
    const today = new Date(item.data.time);
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var time = hours + ":" + minutes + " " + ampm;
    setBetTime(time);
  }, [item]);

  return (
    <div className="bottom mt-3">
      <div className="grid-right">
        <div>
          <p className="mb-0 f-4">
            <span>
              <i
                className="fa fa-minus-circle"
                onClick={() => handleDeleteBet(item.id)}
                aria-hidden="true"
              ></i>
            </span>{" "}
            {item.data.betType == "total"
              ? item.data.betTeam == "home"
                ? "Over"
                : "Under"
              : item.data.team}
          </p>
        </div>
        <p className="mb-0 f-4">{item.data.odds}</p>
      </div>
      <div className="grid-right">
        <p className="mb-0 small-text f-1 mx-3">
          {item.data.betType.toUpperCase()} {language?.bet?.BETTING}
        </p>
      </div>
      <div className="mt-2 betslip-team">
        <p className="f-1 small-text">
          {item.data.homeTeam} @ {item.data.awayTeam}
        </p>
        <p className="f-1 small-text">{betTime}</p>
      </div>
      <div className="betslip-team">
        <div className="row">
          <div className="col-6">
            <div className="input-group mb-1">
              <span className="input-group-text" id="basic-addon1">
                $
              </span>
              <input
                type="number"
                min="0"
                className="form-control"
                placeholder="WAGER"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={item.data.wagerAmount == 0 ? "" : item.data.wagerAmount}
                onChange={(e) => handleBetAmountChange(e)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="input-group mb-1">
              <span className="input-group-text" id="basic-addon1">
                $
              </span>
              <input
                type="number"
                className="form-control"
                placeholder="TO WIN"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={item.data.winningAmount.toFixed(2)}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <hr className="line" />
    </div>
  );
};

export default Bet;

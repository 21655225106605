import React from "react";
import logo from "../assets/images/logo.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import language from "../language.json";

const Header = () => {
  const token = localStorage.getItem("token");

  const wallet = useSelector((state) => state.wallet);

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" data-bs-theme="dark">
      <Container fluid>
        <NavLink to="/">
          <img src={logo} className="h-8 brand_logo" alt="..." />
        </NavLink>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className="justify-content-center flex-grow-1 pe-3 main-menu"
            style={{ gap: 30 }}
          >
            <NavLink to="/nba">{language?.header?.Sports}</NavLink>
            <NavLink to="/howtoplay">{language?.header?.How_to_Play}</NavLink>
            {/* <NavLink to="/faq">{language?.header?.FAQ}</NavLink> */}
            <NavLink to="/contactus">{language?.header?.Contact_Us}</NavLink>
          </Nav>
          <Nav>
            {!token ? (
              <NavLink className="user-icon" to="/login">
                <i className="fa fa-user-circle" aria-hidden="true"></i>
              </NavLink>
            ) : (
              <NavLink className="user-icon" to="/login">
                <span className="logout">{language?.header?.Logout}</span>{" "}
                <i className="fa fa-sign-out" aria-hidden="true"></i>
              </NavLink>
            )}
          </Nav>
          <Nav>
            {token && (
              <>
                <div className="bottom_text text-white">
                  <p className="header-amount">${wallet.balance}</p>
                  <span className="header-bal">
                    {language?.header?.BALANCE}
                  </span>
                </div>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;

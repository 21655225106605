export const objectToFormData = (values) => {
    var payload = new FormData();
    for (var key in values) {
        payload.append(key, values[key]);
    }
    return payload;
}


export const checkExistenceInBets = (id, bets) =>{

    var flag = false;

    bets?.map((item)=>{
        if(item.id == id){
            flag = true;
        }    
    })

    return flag;
}
import React from 'react'
import { NavLink } from 'react-router-dom'
import language from "../language.json";

const Footer = () => {
    return (
        <footer className="footer">
            <span>
                &copy; {language?.footer?.copy_right}
            </span>
            <ul>
                <li className="nav-item"><NavLink to="aboutus">{language?.footer?.ABOUT_US}</NavLink></li>
                {/* <li className="nav-item"><NavLink to="/disclaimer">{language?.footer?.DISCLAIMER}</NavLink></li> */}
                <li className="nav-item"><NavLink to="/privacypolicy">{language?.footer?.PRIVACY_POLICY}</NavLink></li>
                <li className="nav-item"><NavLink to="/contactus">{language?.footer?.CONTACT_US}</NavLink></li>

            </ul>
        </footer>
    )
}

export default Footer
import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.png";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { CRUDServices } from "../services/CRUDServices";
import { toast } from "react-toastify";
import { objectToFormData } from "../functions";
import { useDispatch } from "react-redux";
import { setBalance } from "../redux/walletSlice";
import language from "../language.json";

const Login = () => {
  const [viewPassword, setViewPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginSchema = Yup.object().shape({
    username: Yup.string()
      .email(language?.login?.Invalid_email)
      .required(language?.login?.Email_required),
    password: Yup.string().required(language?.login?.Password_required),
  });

  const initialValues = {
    username: "",
    password: "",
  };

  useEffect(() => {
    localStorage.removeItem("token");
  }, []);

  const handleSumbit = async (values) => {
    setLoading(true);
    var payload = objectToFormData(values);

    const response = await CRUDServices.POST("login", payload);
    setLoading(false);

    if (response.status === 200) {
      localStorage.setItem("token", response.data.data.token);
      localStorage.setItem("userId", response.data.data.user_id);
      const payload = objectToFormData({
        api_username: process.env.REACT_APP_USERNAME,
        api_password: process.env.REACT_APP_PASSWORD,
        user_id: response.data.data.user_id,
      });
      const res = await CRUDServices.POST(
        "get_fantasy_account_balance_by_user_id",
        payload
      );
      dispatch(setBalance(res.data.data[0].account_amount));
      navigate("/");
      toast.success(response.data.message);
    } else {
      var errors = response.data.errors;

      for (var [key, error] of Object.entries(errors)) {
        toast.error(error);
      }
    }
  };

  return (
    <div className="main-bg ">
      <div className="container">
        <div className="row justify-content-center mt-5">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="card shadow">
              <NavLink to="/" className="logo">
                <img src={logo} className="img-logo" alt="logo" />
              </NavLink>
              <div className="card-title text-center">
                <p className="mt-3 mb-1">{language?.login?.Log_to_Account}</p>
              </div>
              <div className="card-body">
                <Formik
                  initialValues={initialValues}
                  validationSchema={loginSchema}
                  onSubmit={(values) => {
                    handleSumbit(values);
                  }}
                >
                  {({ errors, touched }) => {
                    return (
                      <Form>
                        <div className="mb-3">
                          <label htmlFor="username" className="form-label">
                            {language?.login?.Email}
                          </label>
                          <Field
                            className="form-control"
                            id="username"
                            name="username"
                            placeholder={language?.login?.Enter_Email}
                            autocomplete="off"
                          />
                          {errors.username && touched.username ? (
                            <div className="error">{errors.username}</div>
                          ) : null}
                        </div>

                        <div className="mb-2">
                          <label htmlFor="password" className="form-label">
                            {language?.login?.Password}
                          </label>
                          <div className="input-group flex-nowrap">
                            <Field
                              type={viewPassword ? "text" : "password"}
                              className="form-control"
                              placeholder={language?.login?.Enter_Password}
                              aria-label="password"
                              name="password"
                              autocomplete="off"
                            />
                            <span
                              className="input-group-text"
                              id="addon-wrapping"
                              onClick={() => setViewPassword(!viewPassword)}
                            >
                              <i
                                className={
                                  viewPassword ? "fa fa-eye" : "fa fa-eye-slash"
                                }
                              ></i>
                            </span>
                          </div>
                          {errors.password && touched.password ? (
                            <div className="error">{errors.password}</div>
                          ) : null}
                        </div>

                        <div className="mb-4">
                          <NavLink to="/forgotPassword">
                            <span className="forget">
                              {language?.login?.Forgot_Password}
                            </span>
                          </NavLink>
                        </div>
                        <div className="d-grid">
                          <button
                            type="submit"
                            className={
                              loading
                                ? "btn text-light p-3 disabled"
                                : "btn text-light p-3"
                            }
                          >
                            {loading
                              ? language?.login?.Logging_In
                              : language?.login?.Login}
                          </button>
                        </div>
                        <div className="mb-4">
                          <p className="text-center mt-3 bottom-text">
                            {language?.login?.account_not_have}{" "}
                            <NavLink to="/signup">
                              <span className="terms">
                                {language?.login?.Sign_Up}
                              </span>
                            </NavLink>
                          </p>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { objectToFormData } from "../functions";
import { CRUDServices } from "../services/CRUDServices";
import { toast } from "react-toastify";
import language from "../language.json";

const ContactUs = () => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const contactFormSchema = Yup.object().shape({
    name: Yup.string().trim().required(language?.contact_us?.Name_required),
    email: Yup.string()
      .trim()
      .matches(emailRegExp, language?.contact_us?.Invalid_email)
      .required(language?.contact_us?.Email_required),
    phone: Yup.string()
      .trim()
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits")
      .matches(phoneRegExp, language?.contact_us?.Phone_not_valid)
      .required(language?.contact_us?.Phone_required),
    subject: Yup.string()
      .trim()
      .required(language?.contact_us?.Subject_required),
  });

  let initialValues = {
    name: "",
    email: "",
    phone: "",
    subject: "",
  };

  const handleContactSubmit = async (values, resetForm) => {
    setLoading(true);
    var payload = objectToFormData({
      api_username: process.env.REACT_APP_USERNAME,
      api_password: process.env.REACT_APP_PASSWORD,
      mail: values?.email,
      subject: values?.subject,
      message: values?.message,
    });
    const response = await CRUDServices.POST("contactUsSubmit", payload);
    if(response?.errors){
      toast.error(response?.data?.message);
    }else{
      toast.success(response?.data?.message);
    }
    setMessage("");
    resetForm();
    setLoading(false);
  };

  return (
    <div className="dashboard-body">
      <div className="page-bg">
        <h1 className="mb-2 mt-5">{language?.contact_us?.Get_in_touch}</h1>
        <p className="faq-stitle">{language?.contact_us?.get_back_to_you}</p>
        <div className="container p-3">
          <div className="row">
            <div className="col-md-12">
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={contactFormSchema}
                validateOnChange={true}
                onSubmit={(values, { resetForm }) => {
                  handleContactSubmit(values, resetForm);
                }}
              >
                {({ errors, touched }) => {
                  return (
                    <Form>
                      <div className="contactform-bg">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="name" className="form-label">
                                {language?.contact_us?.Your_Name}
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                placeholder={language?.contact_us?.What_name}
                                autocomplete="off"
                                disabled={loading}
                              />
                              {errors.name && touched.name ? (
                                <div className="error">{errors.name}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="email" className="form-label">
                                {language?.contact_us?.Your_email}
                              </label>
                              <Field
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                placeholder={language?.contact_us?.What_Email}
                                autocomplete="off"
                                disabled={loading}
                              />
                              {errors.email && touched.email ? (
                                <div className="error">{errors.email}</div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="phone" className="form-label">
                                {language?.contact_us?.Phone}
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                id="phone"
                                name="phone"
                                placeholder={
                                  language?.contact_us?.phone_placeholder
                                }
                                autocomplete="off"
                                disabled={loading}
                              />
                              {errors.phone && touched.phone ? (
                                <div className="error">{errors.phone}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="subject" className="form-label">
                                {language?.contact_us?.Subject}
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                id="subject"
                                name="subject"
                                placeholder={
                                  language?.contact_us?.Enter_Subject
                                }
                                autocomplete="off"
                                disabled={loading}
                              />
                              {errors.subject && touched.subject ? (
                                <div className="error">{errors.subject}</div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label htmlFor="message" className="form-label">
                                {language?.contact_us?.Message}
                              </label>
                              <textarea
                                className="text-area w-100 form-control"
                                placeholder={
                                  language?.contact_us?.message_placeholder
                                }
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                                disabled={loading}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <button
                            type="submit"
                            className="btn submit-btn text-light"
                            disabled={loading}
                          >
                            {language?.contact_us?.Send_Message}
                          </button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

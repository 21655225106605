import React from "react";
import { Modal, ModalBody } from "reactstrap";
import playerPlaceholder from "../assets/images/player_placeholder.jpg";

const PlayerStatus = ({
  isPlayerModalOpen,
  setIsPlayerModalOpen,
  activePlayer,
}) => {
  return (
    <Modal
      isOpen={isPlayerModalOpen}
      className="player_status_wrapper"
      toggle={() => setIsPlayerModalOpen(false)}
    >
      <ModalBody>
        <div className="player_data_wrapper">
          <div
            className="close_player_modal"
            onClick={() => setIsPlayerModalOpen(false)}
          >
            X
          </div>
          <div className="player_info_wrapper">
            <div className="player_img_wrapper">
              <img src={playerPlaceholder} alt="player" />
            </div>
            <div className="player_detail_wrapper">
              <div>
                <h3>27</h3>
              </div>
              <div>
                <h2>{activePlayer?.Playersname}</h2>
                <p>{activePlayer?.team}</p>
              </div>
              <div>
                <p>6-5 / 205 lbs</p>
                <p>Born: 1999-11-11</p>
                <p>Drafted: 2022</p>
                <p>Experience: 1 years</p>
                <p>Prior to NBA: Syracuse/United States</p>
              </div>
            </div>
          </div>
          <h3 className="bg-primary text-white m-0 px-2">Season Status</h3>
          <div className="team_table_wrapper">
            <div className="team_data_wrapper p-md-4 px-sm-2 py-sm-4">  
              <div className="d-flex justify-content-start align-items-center">
                <p>Season</p>
                <p>Team</p>
                <p>BLK</p>
                <p>DRB</p>
                <p>GS</p>
                <p>MP</p>
                <p>PTS</p>
                <p>STL</p>
                <p>TOV</p>
                <p>ptsmin</p>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <p>{activePlayer?.season}</p>
                <p className="text-primary">{activePlayer?.team}</p>
                <p>{activePlayer?.BLK}</p>
                <p>{activePlayer?.DRB}</p>
                <p>{activePlayer?.GS}</p>
                <p>{activePlayer?.MP}</p>
                <p>{activePlayer?.PTS}</p>
                <p>{activePlayer?.STL}</p>
                <p>{activePlayer?.TOV}</p>
                <p>{activePlayer?.ptsmin}</p>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PlayerStatus;

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Bet from "./Bet";
import { objectToFormData } from "../functions";
import { CRUDServices } from "../services/CRUDServices";
import { useDispatch, useSelector } from "react-redux";
import { setBalance } from "../redux/walletSlice";
import language from "../language.json";

const Betslip = ({ bets, setBets, myBet }) => {
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);

  const [betsTotal, setBetsTotal] = useState(0);

  const handleDeleteBet = (id) => {
    var newBetsArray = bets?.filter((item) => item.id != id);
    localStorage.setItem("bets", JSON.stringify(newBetsArray));
    setBets(newBetsArray);
    toast.success(language?.Betslip?.Bet_deleted);
  };

  const getWalletBalance = async () => {
    const payload = objectToFormData({
      api_username: process.env.REACT_APP_USERNAME,
      api_password: process.env.REACT_APP_PASSWORD,
      user_id: localStorage.getItem("userId"),
    });
    const res = await CRUDServices.POST(
      "get_fantasy_account_balance_by_user_id",
      payload
    );
    dispatch(setBalance(res?.data?.data?.[0]?.account_amount));
  };

  useEffect(() => {
    var localStorageBets = localStorage.getItem("bets");
    if (localStorageBets) {
      var betsData = JSON.parse(localStorageBets);
      if (betsData != null) {
        setBets(betsData);
      }
    }
  }, []);

  useEffect(() => {
    var sum = 0;

    bets.map((item) => {
      sum += Number(item.data.wagerAmount);
    });
    setBetsTotal(sum);
  }, [bets]);

  const handlePlaceBet = async () => {
    var payload = [];

    bets.map((item) => {
      var { data } = item;
      var abc = {
        api_username: process.env.REACT_APP_USERNAME,
        api_password: process.env.REACT_APP_PASSWORD,
        game_id: data.gameId,
        team_id: data.teamId,
        user_id: localStorage.getItem("userId"),
        bet_type: data.betType,
        gametime_quaters: 1,
        bet_amount: parseFloat(data.wagerAmount),
        payouts: parseFloat(data.winningAmount),
        odds: data.odds,
        total_ou:
          data.betType == "total" ? (data.betTeam == "home" ? "o" : "u") : null,
      };
      payload.push(abc);
    });

    var betTotal = 0;
    var isBetAmoutZero = false;
    var isBetAmoutNegative = false;

    payload.map((item) => {
      betTotal += item.bet_amount;

      if (item.bet_amount == 0) {
        isBetAmoutZero = true;
      } else if (item.bet_amount < 0) {
        isBetAmoutNegative = true;
      }
    });

    if (isBetAmoutZero) {
      toast.error(language?.Betslip?.Bet_amount_zero);
    } else if (isBetAmoutNegative) {
      toast.error(language?.Betslip?.Bet_negative);
    } else if (betTotal > wallet.balance) {
      toast.error(language?.Betslip?.Low_balance);
    } else {
      payload.map(async (item, index) => {
        const apiPayload = objectToFormData(item);
        const response = await CRUDServices.POST(
          "insert_fantasy_bet_slip",
          apiPayload
        );

        if (response.data.statusCode == 200) {
          toast.success(language?.Betslip?.Bet_success);
        } else {
          toast.error(language?.Betslip?.went_wrong);
        }
        if (index == payload.length - 1) {
          getWalletBalance();
        }
      });
      localStorage.removeItem("bets");
      setBets([]);
    }
  };

  return (
    <div className={myBet ? "col-lg-3 orderfirst show" : "col-lg-3 orderfirst"}>
      <div className="betslip-fixed">
        <div className="sport-right-card">
          <div className="sport-heading">
            <p className="mb-0 betslip-heading">{language?.Betslip?.BETSLIP}</p>
            <p className="mb-0">
              {language?.Betslip?.Total}${betsTotal}
            </p>
            <NavLink
              to="/mybets"
              className="btn btn-outline-secondary"
              style={{ marginLeft: 30 }}
            >
              <NavLink to="/mybets">{language?.Betslip?.My_Bets}</NavLink>
            </NavLink>
          </div>
          <div className="sport-right-content">
            <div className="table-scroll-right">
              {bets?.length !== 0 ? (
                bets?.map((item, index) => {
                  return (
                    <Bet
                      key={index}
                      item={item}
                      handleDeleteBet={handleDeleteBet}
                      bets={bets}
                      setBets={setBets}
                      index={index}
                    />
                  );
                })
              ) : (
                <div>
                  <span className="center">
                    {language?.Betslip?.No_pending}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="remove-btn">
          <hr className="line mb-0" />
          <p
            onClick={() => {
              setBets([]);
              localStorage.removeItem("bets");
            }}
          >
            <span className="remove-icon">
              <i className="fa fa-trash-o" aria-hidden="true"></i>
            </span>{" "}
            {language?.Betslip?.Remove_all_selections}
          </p>
          <hr className="line mt-0" />
        </div>
        <div className="remove-btn">
          {token ? (
            <button
              className="btn btn-loginbet"
              style={{ color: "white" }}
              onClick={handlePlaceBet}
            >
              {language?.Betslip?.Bet}
            </button>
          ) : (
            <NavLink to="/login" className="btn btn-loginbet">
              {language?.Betslip?.Login_to_Bet}
            </NavLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default Betslip;

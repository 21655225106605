import React, { useEffect, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import { CRUDServices } from "../services/CRUDServices";
import { objectToFormData } from "../functions";
import bg from "../assets/images/bg.png";
import vs from "../assets/images/vs.png";
import t1 from "../assets/images/t1.png";
import t2 from "../assets/images/t2.png";
import n1 from "../assets/images/n1.png";
import q1 from "../assets/images/q1.png";
import sample from "../assets/animations/sample.mp4";
import sample2 from "../assets/animations/sample2.mp4";
import first from "../assets/animations/first.mp4";
import second from "../assets/animations/second.mp4";
import third from "../assets/animations/third.mp4";
import fourth from "../assets/animations/fourth.mp4";
import gameOver from "../assets/animations/GameOver.mp4";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TeamTable from "../components/TeamTable";
import VideoPlayer from "../components/VideoPlayer";
import { Audio } from "react-loader-spinner";
import useWindowDimensions from "../utils/WindowDimensions";
import language from "../language.json";

const roundVideo = [
  {
    video_fullpath: first,
    duration: "3",
  },
  {
    video_fullpath: second,
    duration: "3",
  },
  {
    video_fullpath: third,
    duration: "3",
  },
  {
    video_fullpath: fourth,
    duration: "3",
  },
  {
    video_fullpath: gameOver,
    duration: "3",
  },
];

const SingleLeague = () => {
  const location = useLocation();
  const matchData = location?.state?.data;
  const league = location?.state?.league || "nba";

  const [homeTeamPlayersData, setHomeTeamPlayersData] = useState([]);
  const [awayTeamPlayersData, setAwayTeamPlayersData] = useState([]);
  const [quarter, setQuarter] = useState("");
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [activeTeam, setActiveTeam] = useState("home");
  const [timer, setTimer] = useState(0);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [quarterLabels, setQuarterLabels] = useState({
    Quarter1: "Quarter1",
    Quarter2: "Quarter2",
    Quarter3: "Quarter3",
    Quarter4: "Quarter4",
  });

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width >= 500) {
      setQuarterLabels({
        Quarter1: "Quarter1",
        Quarter2: "Quarter2",
        Quarter3: "Quarter3",
        Quarter4: "Quarter4",
      });
    } else {
      setQuarterLabels({
        Quarter1: "Q1",
        Quarter2: "Q2",
        Quarter3: "Q3",
        Quarter4: "Q4",
      });
    }
  }, [width]);

  useEffect(() => {
    timer == 22 && setQuarter(quarterLabels?.Quarter1);
    timer == 40 && setQuarter(quarterLabels?.Quarter2);
    timer == 58 && setQuarter(quarterLabels?.Quarter3);
    timer == 76 && setQuarter(quarterLabels?.Quarter4);
  }, [timer]);

  const getTeamPlayersInfo = async (teamId, team) => {
    var payload = objectToFormData({
      team_id: teamId,
      api_username: process.env.REACT_APP_USERNAME,
      api_password: process.env.REACT_APP_PASSWORD,
    });
    const response = await CRUDServices.POST(
      "get_fantasy_players_by_team_id",
      payload
    );

    if (team == "home") {
      setSelectedTeam(response.data.data);
      setHomeTeamPlayersData(response.data.data);
    } else {
      setAwayTeamPlayersData(response.data.data);
    }
  };

  const getVideosWithRound = (arr1, arr2) => {
    const resultant = [];
    const maxLength = Math.max(arr1.length, arr2.length);

    for (let i = 0; i < maxLength; i++) {
      if (i < arr1.length) {
        resultant.push(arr1[i]);
      }
      if (i < arr2.length) {
        resultant.push(arr2[i]);
      }
    }
    return resultant;
  };

  const getTeamVideos = async () => {
    var payload = objectToFormData({
      api_username: process.env.REACT_APP_USERNAME,
      api_password: process.env.REACT_APP_PASSWORD,
      fisrt_team: matchData?.TeamIdhome,
      second_team: matchData?.TeamIdaway,
    });
    const response = await CRUDServices.POST(
      "get_fantasy_video_by_teams_id",
      payload
    );
    if (response?.status === 200) {
      getVideosWithRound(roundVideo, response?.data?.data);
      // setVideos(response?.data?.data);
      setVideos(getVideosWithRound(roundVideo, response?.data?.data));
    } else {
      setVideos([]);
    }
    setLoading(false);
    console.log("get_fantasy_video_by_teams_id response", response?.data?.data);
  };

  useEffect(() => {
    setLoading(true);
    getTeamVideos();
    getTeamPlayersInfo(matchData?.TeamIdhome, "home");
    getTeamPlayersInfo(matchData?.TeamIdaway, "away");
  }, [matchData]);

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      setTimer(timer + 1);
    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [timer]);

  const renderTabs = useMemo(() => {
    return (
      <Tabs
        activeKey={quarter}
        onSelect={(k) => setQuarter(k)}
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        {timer >= 22 && (
          <Tab eventKey={quarterLabels?.Quarter1} title={quarterLabels?.Quarter1}>
            <Table responsive="sm">
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Score</th>
                  <th>Team</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{matchData.TeamNamehome}</td>
                  <td>{matchData.Q1HomeScore}</td>
                  <td>{matchData.TeamNameaway}</td>
                  <td>{matchData.Q1AwayScore}</td>
                </tr>
              </tbody>
            </Table>
          </Tab>
        )}
        {timer >= 40 && (
          <Tab eventKey={quarterLabels?.Quarter2} title={quarterLabels?.Quarter2}>
            <Table responsive="sm">
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Score</th>
                  <th>Team</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{matchData.TeamNamehome}</td>
                  <td>{matchData.Q2HomeScore}</td>
                  <td>{matchData.TeamNameaway}</td>
                  <td>{matchData.Q2AwayScore}</td>
                </tr>
              </tbody>
            </Table>
          </Tab>
        )}
        {timer >= 58 && (
          <Tab eventKey={quarterLabels?.Quarter3} title={quarterLabels?.Quarter3}>
            <Table responsive="sm">
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Score</th>
                  <th>Team</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{matchData.TeamNamehome}</td>
                  <td>{matchData.Q3HomeScore}</td>
                  <td>{matchData.TeamNameaway}</td>
                  <td>{matchData.Q3AwayScore}</td>
                </tr>
              </tbody>
            </Table>
          </Tab>
        )}
        {timer >= 76 && (
          <Tab eventKey={quarterLabels?.Quarter4} title={quarterLabels?.Quarter4}>
            <Table responsive="sm">
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Score</th>
                  <th>Team</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{matchData.TeamNamehome}</td>
                  <td>{matchData.Q4HomeScore}</td>
                  <td>{matchData.TeamNameaway}</td>
                  <td>{matchData.Q4AwayScore}</td>
                </tr>
              </tbody>
            </Table>
          </Tab>
        )}
      </Tabs>
    );
  }, [quarter]);

  const changeTeam = (team) => {
    if (team === "home") {
      setActiveTeam("home");
      setSelectedTeam(homeTeamPlayersData);
    } else {
      setActiveTeam("away");
      setSelectedTeam(awayTeamPlayersData);
    }
  };

  return (
    <div className="sport-mid-card">
      <div className="sport-heading">
        <p className="mb-0 text-uppercase">
          <NavLink to={`/${league}`}>
            <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>
          </NavLink>{" "}
          | {league} LEAGUE
        </p>
      </div>

      <div className="parent">
        {loading ? (
          <div className="flex-standings">
            <Audio
              height="180"
              width="120"
              radius="200"
              color="white"
              ariaLabel="loading"
              wrapperStyle
              wrapperClass
            />
          </div>
        ) : (
          <VideoPlayer matchData={matchData} videos={videos} />
        )}
      </div>
      <div className="p-3 single-detail">
        {timer >= 22 && <div className="accordian-tabs my-4">{renderTabs}</div>}
      </div>

      <TeamTable
        selectedTeam={selectedTeam}
        activeTeam={activeTeam}
        matchData={matchData}
        changeTeam={changeTeam}
      />
    </div>
  );
};

export default SingleLeague;

import React, { useEffect } from "react";
import Countdown from "react-countdown";
import language from "../language.json";

const Countdownn = ({ date, setIsLive }) => {

  const Completionist = () => {
    useEffect(() => {
      setIsLive(true);
    })
  };

  // Renderer callback with condition
  const renderer = (data) => {
    var { hours, minutes, seconds, completed } = data;
    var days = data.formatted.days;

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <p className="mb-0" style={{ color: "white" }}>
          {language?.count_down?.starts_in}{" "}
          <span className="league-timer">
            {days == "00" ?
              <>{hours}:{minutes}:{seconds}</>
              :
              days == "01" ?
                <>{days} day</> :
                <>{days} days</>
            }

          </span>
        </p>
      );
    }
  };

  return <Countdown date={date} renderer={renderer} />;
};

export default Countdownn;

import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import logo from "../assets/images/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { objectToFormData } from "../functions";
import { CRUDServices } from "../services/CRUDServices";
import { toast } from "react-toastify";

const SignUp = () => {
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [signupLocations, setSignupLocations] = useState([]);
  const [usernameCheckResponse, setUsernameCheckResponse] = useState("");
  const navigate = useNavigate();

  const fetchSignupLocations = async () => {
    var payload = new FormData();
    payload.append("api_username", process.env.REACT_APP_USERNAME);
    payload.append("api_password", process.env.REACT_APP_PASSWORD);

    const response = await CRUDServices.POST("get-signup-locations", payload);
    setSignupLocations(response.data.data);
  };

  useEffect(() => {
    fetchSignupLocations();
  }, []);

  const signUpSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    username: Yup.string()
      .min(6, "Minimum 6 characters are required")
      .max(15, "Maximum 15 characters are allowed")
      .required("Username  is required"),
    password: Yup.string()
      .matches(
        // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8}$/,
        /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,64})/g,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character!"
      )
      .required("Please enter your password!"),
    confirmPassword: Yup.string()
      .required("Please confirm your password!")
      .oneOf([Yup.ref("password"), null], "Passwords do not match"),
    location: Yup.string().required("Location is required"),
    termsAndConditions: Yup.bool().oneOf(
      [true],
      "You need to accept the terms and conditions"
    ),
  });

  const initialValues = {
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
    location: "US",
    termsAndConditions: false,
  };

  const handleSumbit = async (values) => {
    setLoading(true);
    var payload = {};
    payload.mail = values.email;
    payload.username = values.username;
    payload.password = values.password;
    payload.confirm_password = values.confirmPassword;
    payload.is_agree_terms = "Y";
    payload.location_code = values.location;

    var data = objectToFormData(payload);

    const response = await CRUDServices.POST("sign-up", data);
    setLoading(false);

    if (response.status === 200) {
      // addSignUpBalance(response?.data?.data?.user_id)
      navigate("/login");
      toast.success(response.data.message);
    } else {
      var errors = response.data.errors;

      for (var [key, error] of Object.entries(errors)) {
        toast.error(error);
      }
    }
  };

  const addSignUpBalance = async(user_id) =>{
    const payload = objectToFormData({
      api_username: process.env.REACT_APP_USERNAME,
      api_password: process.env.REACT_APP_PASSWORD,
      user_id: user_id,
    });
    const response = await CRUDServices.POST("add_fantasy_account_balance_by_user_id", payload);
    if(response?.data?.statusCode === 200){
      toast.success(response.data.message);
    }else{
      toast.error("Failed to add amount")
    }
  }

  const handleCheckUsername = async () => {
    if (username.length > 5) {
      var values = {
        username,
        api_username: "FANDEX_DEV_TEAM",
        api_password: "FANDEX_PASSWORD_@#",
      };
      var payload = objectToFormData(values);
      const response = await CRUDServices.POST("checkUsername", payload);
      setUsernameCheckResponse(response.data.message);
      if (
        response?.data?.data?.username_exist ||
        response?.data?.statusCode === 409
      ) {
        toast.error(response.data.message);
      } else {
        toast.success(response.data.message);
      }
    }
  };

  return (
    <div className="main-bg">
      <div className="container">
        <div className="row justify-content-center mt-5">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="card shadow login-card">
              <NavLink to="/" className="logo">
                <img src={logo} className="img-logo" alt="logo" />
              </NavLink>
              <div className="card-title text-center">
                <p className="mt-1 mb-0">Create an Account</p>
              </div>
              <div className="card-body">
                <Formik
                  initialValues={initialValues}
                  validationSchema={signUpSchema}
                  onSubmit={(values) => {
                    handleSumbit(values);
                  }}
                >
                  {({ errors, touched, handleChange }) => {
                    return (
                      <Form>
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            id="email"
                            placeholder="Enter Your Email"
                            name="email"
                            autocomplete="off"
                          />
                          {errors.email && touched.email ? (
                            <div className="error">{errors.email}</div>
                          ) : null}
                        </div>
                        <div className="">
                          <label htmlFor="username" className="form-label">
                            Username
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            id="username"
                            placeholder="Enter Your Username"
                            name="username"
                            autocomplete="off"
                            onChange={(e) => {
                              handleChange(e);
                              let name = e.target.value.trim();
                              setUsername(name);
                            }}
                            value={username}
                          />
                          <div className="error-section">
                            {errors.username && touched.username ? (
                              <span className="error">{errors.username}</span>
                            ) : null}
                            <span className="link-container mb-0">
                              <p
                                className={
                                  username.length > 5 ? "link" : "link disabled"
                                }
                                onClick={handleCheckUsername}
                              >
                                Check username
                              </p>
                            </span>
                          </div>
                        </div>

                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <div className="input-group flex-nowrap">
                            <Field
                              type={viewPassword ? "text" : "password"}
                              className="form-control"
                              placeholder="Enter Your Password"
                              aria-label="password"
                              name="password"
                              autocomplete="off"
                              maxLength={20}
                            />
                            <span
                              className="input-group-text"
                              id="addon-wrapping"
                              onClick={() => setViewPassword(!viewPassword)}
                            >
                              <i
                                className={
                                  viewPassword ? "fa fa-eye" : "fa fa-eye-slash"
                                }
                              ></i>
                            </span>
                          </div>
                          {errors.password && touched.password ? (
                            <div className="error">{errors.password}</div>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor="confirmPassword"
                            className="form-label"
                          >
                            Confirm Password
                          </label>
                          <div className="input-group flex-nowrap">
                            <Field
                              type={viewConfirmPassword ? "text" : "password"}
                              className="form-control"
                              placeholder="Enter Your  Confirm Password"
                              aria-label="password"
                              name="confirmPassword"
                              autocomplete="off"
                              maxLength={20}
                            />
                            <span
                              className="input-group-text"
                              id="addon-wrapping"
                              onClick={() =>
                                setViewConfirmPassword(!viewConfirmPassword)
                              }
                            >
                              <i
                                className={
                                  viewConfirmPassword
                                    ? "fa fa-eye"
                                    : "fa fa-eye-slash"
                                }
                              ></i>
                            </span>
                          </div>
                          {errors.confirmPassword && touched.confirmPassword ? (
                            <div className="error">
                              {errors.confirmPassword}
                            </div>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <label htmlFor="location" className="form-label">
                            Location
                          </label>
                          <Field
                            as="select"
                            name="location"
                            className="form-select form-control"
                            aria-label="Select Location"
                          >
                            {signupLocations?.map((country, index) => {
                              return (
                                <option
                                  value={country.country_origin_code}
                                  key={index}
                                >
                                  {country.name}
                                </option>
                              );
                            })}
                          </Field>
                          {errors.location && touched.location ? (
                            <div className="error">{errors.location}</div>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="remember"
                            name="termsAndConditions"
                          />
                          <label
                            for="remember"
                            className="form-label bottom-text mx-2"
                          >
                            {" "}
                            I agree to{" "}
                            <span className="terms">terms & conditions</span>
                          </label>
                          {errors.termsAndConditions &&
                            touched.termsAndConditions && (
                              <div className="error">
                                {errors.termsAndConditions}
                              </div>
                            )}
                        </div>
                        <div className="d-grid">
                          <button
                            type="submit"
                            className={
                              loading
                                ? "btn text-light p-3 disabled"
                                : "btn text-light p-3"
                            }
                          >
                            {loading ? "Registering..." : "Register Now"}
                          </button>
                        </div>
                        <div className="mb-3">
                          <p className="text-center mt-3 bottom-text">
                            Already have an account?{" "}
                            <NavLink to="/login">
                              <span className="terms">Log in</span>
                            </NavLink>{" "}
                          </p>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
